import { useField } from 'formik';
import { useEffect, useState } from 'react';
import { SelectOptionType } from '@mosru/esz_uikit';
import FormikSelect from '../../../../components/formik/formik-select';
import Field, { FieldProps } from '../../../../components/fields/field';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { capacityFieldName } from './pupil-in-group';
import { serviceIdField } from './service';

type ModuleFieldProps = FieldProps<string> & {
  onChange?: (selected?: SelectOptionType) => void;
};

export const moduleFieldId = 'programmModuleId';
export const moduleFieldName = 'programmModuleName';

const ModuleField = ({
  label = 'Модуль',
  name,
  editMode,
  required,
  defaultValue = '—',
  disabled,
  onChange,
}: ModuleFieldProps) => {
  const fieldName = `${name}Name`;
  const fieldIdName = `${name}Id`;
  const [serviceField] = useField<number>(serviceIdField);
  const [, , capacityHelpers] = useField<number>(capacityFieldName);

  const [moduleData, setModuleData] = useState<SelectOptionType[]>([]);

  const [fieldLabel, , helpersLabel] = useField(fieldName);
  const [fieldId] = useField<number>(fieldIdName);

  // загрузка справочиков
  useEffect(() => {
    const fetchTrainGroup = async (serviceId: number) => {
      setModuleData(
        await serviceTemplateApi.getListProgramModule({
          serviceId,
        })
      );
    };

    if (serviceField.value) {
      fetchTrainGroup(serviceField.value);
    } else {
      setModuleData([]);
    }
  }, [serviceField.value]);

  const handleSelect = (selected?: SelectOptionType) => {
    helpersLabel.setValue(selected ? selected.label : undefined);
    capacityHelpers.setValue(0);
    onChange?.(selected);
  };

  return (
    <Field
      label={label}
      editMode={editMode}
      required={required}
      value={fieldLabel.value}
      defaultValue={defaultValue}
    >
      <FormikSelect
        name={fieldIdName}
        size="small"
        withSearch
        options={moduleData}
        defaultValue={
          fieldId.value
            ? {
                value: fieldId.value,
                label: fieldLabel.value,
              }
            : null
        }
        selectedValue={handleSelect}
        placeholder="Начните вводить или выберите..."
        disabled={disabled}
      />
    </Field>
  );
};

export default ModuleField;

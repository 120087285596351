import React from 'react';
import { Push } from '@mosru/esz_uikit';
import { LmInfoBox } from '@mes-ui/lemma';
import { RequestData } from '../../../../types/requests';

type Props = {
  setOpen: (value: boolean) => void;
  disabled: boolean;
  requestData?: RequestData;
};

export const InfoLike = ({ setOpen, disabled, requestData }: Props) => {
  return requestData ? (
    <div className="container">
      {requestData.contingentLinkTypeId >= 3 && requestData.contingentGuid && (
        <>
          <LmInfoBox
            dataTest="infoLikeWarning"
            className="infobox--full-width"
            variant="warning"
            description="Обучающийся идентифицирован в Реестре контингента, сведения об общеобразовательной организации отсутствуют. Функционал зачисления доступен"
            hidenFooter
          />
          <Push
            size={16}
            orientation="vertical"
          />
        </>
      )}

      {!requestData.contingentGuid && (
        <>
          <LmInfoBox
            dataTest="infoLikeError"
            className="infobox--full-width"
            variant="alert"
            description="Функционал зачисления недоступен, так как сведения об обучающемся отсутствуют в Реестре контингента. Вам необходимо проверить эти сведения и идентифицировать обучающегося."
            buttonPrimaryText="Проверить сведения"
            onClickPrimaryButton={() => setOpen(true)}
            primaryButtonModifiers={{
              disabled,
            }}
            hideSecondaryButton
          />
          <Push size={16} />
        </>
      )}
    </div>
  ) : null;
};

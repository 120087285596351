import React, { useContext, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { array as arrayYup, object as objectYup, string as stringYup } from 'yup';
import { useHistory } from 'react-router-dom';
import { Panel, Push } from '@mosru/esz_uikit';
import { LmSubHeader } from '@mes-ui/lemma';
import { routes } from '../../../config/constants';
import { ServiceClassContext } from '../service-class';
import { ServiceClassData, TeacherType, TrainingGroupItemType } from '../../../types/service-class';
import serviceClassApi from '../../../lib/api/service-class';
import history from '../../../history';
import { generateLink, getEmptyFunctionToOverride } from '../../../lib/utils';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import ServiceField from '../components/fields/service';
import SimpleInput from '../../../components/fields/simple-input';
import DateField from '../components/fields/date';
import PupilInGroupField from '../components/fields/pupil-in-group';
import SimpleTextArea from '../../../components/fields/simple-textarea';
import TeachersField from '../components/fields/teachers';
import { serviceClassName, serviceProgram } from '../../../lib/utils/validation';
import { EducationTypeEnum } from '../../../types/education-type';
import ModuleField from '../components/fields/module';
import AddressField from '../components/fields/address';
import TrainingGroupField from '../components/fields/training-group';
import {
  editPeriodDataToTransformRequestData,
  extendedPeriods,
  maxCommentLength,
  scheduleDataCreateService,
} from '../../../lib/utils/service-class';
import EditPeriodSchedule, { PeriodType } from '../../../components/schedule/period';
import SavePanel from '../../../components/save-panel';
import { capacityOPValidation, validationPreparationTrainDate } from '../utils';

const ServiceClassPreparationCreate = () => {
  const [loadBtn, setLoadBtn] = useState<boolean>(false);
  const [scheduleSubmit, setScheduleSubmit] = useState(false);
  const [scheduleIsError, setScheduleIsError] = useState(false);
  const [currentTrainingGroup, setCurrentTrainingGroup] = useState<TrainingGroupItemType | null>(null);

  const { serviceClassData, startDateSchedulePeriod, endDateSchedulePeriod } = useContext(ServiceClassContext);
  const [schedule, setSchedule] = useState<PeriodType[]>(scheduleDataCreateService('days'));

  const historyRouter = useHistory();

  const submitForm = async (values: any) => {
    const v = {
      ...values,
      schedule: {
        serviceClassId: serviceClassData.serviceId,
        educationTypeId: serviceClassData.educationTypeId,
        list: editPeriodDataToTransformRequestData(values.schedule),
      },
    };

    try {
      values.teacher.list = values.teachers || [];
      const id = await serviceClassApi.createServiceClass(v);

      history.push(
        generateLink(routes.serviceClass, {
          id,
        })
      );
    } catch {}
    setLoadBtn(false);
  };

  const initialErrors = useInitialErrors(serviceClassData, getValidationSchema(currentTrainingGroup));

  const handleCancel = () => {
    history.push(routes.serviceClasses);
  };

  const handleSubmit = () => {
    setLoadBtn(true);
    setScheduleSubmit(true);
  };

  return (
    <>
      <LmSubHeader
        sticky
        description=""
        arrowOnClick={() => historyRouter.push(generateLink(routes.serviceClasses, {}))}
        title="Новая группа"
        dataTest="serviceClassPreparationCreateSubHeader"
        routes={[
          {
            label: 'Главная',
            link: routes.main,
          },
          {
            label: 'Группы обучения',
            link: routes.serviceClasses,
          },
          {
            label: 'Группа обучения',
          },
        ]}
      />
      <Formik
        enableReinitialize
        initialValues={serviceClassData}
        validationSchema={getValidationSchema(currentTrainingGroup)}
        initialErrors={initialErrors}
        // Из-за компонента расписания пришлось вынести onSubmit.
        onSubmit={getEmptyFunctionToOverride()}
      >
        {(formikProps: FormikProps<ServiceClassData>) => {
          const { isValid, values } = formikProps;

          return (
            <form>
              <Push size={12} />
              <Panel title={() => <>Сведения о группе</>}>
                <div className="container">
                  <div className="table-data">
                    <ServiceField
                      required
                      name="service"
                      editMode
                      educationType={EducationTypeEnum.ProfessionalEducation}
                      isNewServiceClass
                      label="Образовательная программа"
                    />
                    <ModuleField
                      name="programmModule"
                      label="Модуль"
                      editMode
                      required
                    />

                    <SimpleInput
                      name="name"
                      editMode
                      required
                      label="Наименование группы"
                      placeholder="Введите..."
                    />

                    <AddressField
                      name="address"
                      editMode
                      required
                      label="Адрес"
                      isCreatable
                    />
                    <TrainingGroupField
                      name="trainingGroup"
                      defaultValue="—"
                      editMode
                      required
                      setCurrentTrainingGroup={setCurrentTrainingGroup}
                      educationType={EducationTypeEnum.ProfessionalEducation}
                    />

                    <DateField
                      label="Даты занятий"
                      name="train"
                      editMode
                      required
                      isNewServiceClass
                    />

                    <PupilInGroupField
                      name="included"
                      label="Человек в группе"
                      editMode
                      required
                      isNewServiceClass
                    />

                    <SimpleTextArea
                      name="description"
                      label="Комментарий"
                      editMode
                      placeholder="Введите..."
                      maxLength={maxCommentLength}
                    />
                  </div>
                </div>
              </Panel>
              <Push size={12} />
              <EditPeriodSchedule
                key={`${String(startDateSchedulePeriod)} ${String(endDateSchedulePeriod)}`}
                dateStartProps={startDateSchedulePeriod}
                dateEndProps={endDateSchedulePeriod}
                title="Время проведения занятий"
                submit={scheduleSubmit}
                submitError={() => {
                  setScheduleSubmit(false);
                }}
                submitSuccess={(schedule) => {
                  setSchedule(schedule);
                  if (scheduleSubmit) {
                    submitForm({
                      ...values,
                      schedule,
                    });
                  }
                  setScheduleSubmit(false);
                }}
                extendedPeriods={extendedPeriods(schedule, startDateSchedulePeriod, endDateSchedulePeriod)}
                setCurrentErrorSchedule={setScheduleIsError}
              />
              <Push size={12} />
              <Panel
                title={() => (
                  <>
                    Ответственные преподаватели <span className="color-gray-dark">{' \u00A0'}</span>
                  </>
                )}
              >
                <TeachersField
                  name="teachers"
                  hasCreateServiceClass
                />
              </Panel>

              <SavePanel
                buttonPrimaryText="Создать группу"
                primaryButtonModifiers={{
                  loading: loadBtn,
                  disabled: !isValid || scheduleIsError,
                }}
                onClickSeconadaryButton={handleCancel}
                onClickPrimaryButton={handleSubmit}
              />
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default ServiceClassPreparationCreate;

const getValidationSchema = (currentTrainingGroup: TrainingGroupItemType | null) => {
  const depsDate: [string, string] = ['trainStartDate', 'trainEndDate'];

  return objectYup().shape(
    {
      serviceId: serviceProgram,
      programmModuleId: stringYup().required('Выберите модуль'),
      address: stringYup().required('Выберите адрес'),
      name: serviceClassName,
      trainingGroupId: stringYup().required('Выберите план приема'),
      teachers: arrayYup()
        .min(1, 'Добавьте хотя бы одного преподователя')
        .test('test teachers', '', (value) => {
          return (value as TeacherType[]).some((v) => v.isSupervisor);
        })
        .of(
          objectYup().shape({
            name: stringYup().required('Введите ФИО преподавателя'),
          })
        ),
      capacity: capacityOPValidation(currentTrainingGroup),
      ...validationPreparationTrainDate(currentTrainingGroup),
    },
    [depsDate]
  );
};

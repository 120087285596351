import React, { useEffect, useMemo, useState } from 'react';
import { Field as FormikField, FieldProps as FormikFieldProps } from 'formik';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { SelectOptionType, Push } from '@mosru/esz_uikit';
import { LmIcon, LmInfoBox } from '@mes-ui/lemma';
import { ChildrenSearchResult } from '../../../../types/contingent';
import { dictionariesApi } from '../../../../lib/api/dictionaries';
import { fioFirstNameField, fioLastNameField, fioMiddleNameField } from './fio';
import { birthDateField } from './birthdate';
import { genderIdField } from '../../../../components/fields/gender';
import { snilsField } from './snils';
import { docNumberDateOfIssueField, docNumberField, docNumberSeriesField } from './document-number';
import { documentTypeField, documentTypeFieldName, issuedFieldName } from './document';
import { buildFormFieldName } from '../../../../lib/utils/requests';
import { ContingentLinkTypeEnum } from '../../../../mock-data/contingent-link-type-enum';
import { schoolOrganizationIdField, schoolOrganizationNameField } from './organization';
import {
  classLetterlIdField,
  classLetterlNameField,
  classParallelIdField,
  classParallelNameField,
} from './class-parallel-leter';
import { EducationFact } from '../../../../types/learners';
import learnerApi from '../../../../lib/api/learner';
import { filterEducations } from '../../../../lib/utils/education';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';
import { accessAction, accessObject } from '../../../../mock-data/access-enum';
import { DocumentTypeEnum } from '../../../../mock-data/type-document';
import { EducationTypeEnum } from '../../../../types/education-type';

type ContingentSearchProps = {
  editMode: boolean;
  childrenList?: ChildrenSearchResult[] | null;
  setChildrenList?: React.Dispatch<React.SetStateAction<ChildrenSearchResult[] | null>>;
  parent?: string;
  setIsClickedSelect?: (value: boolean) => void;
  hasSearchOrganization?: boolean;
};

const defaultVisibleCount = 2;
const contingentGuidField = 'contingentGuid';
const contingentLinkTypeField = 'contingentLinkTypeId';

const ContingentSearch = ({
  setIsClickedSelect,
  editMode,
  childrenList = null,
  setChildrenList,
  parent,
  hasSearchOrganization,
}: ContingentSearchProps) => {
  const [showAllLearners, setShowAllLearners] = useState(false);
  const [documentTypes, setDocumentTypes] = useState<SelectOptionType[] | undefined>(undefined);

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const educationTypeData = useMemo(
    () =>
      filterEducations(
        userProfile.objectAccessActionList,
        [],
        undefined,
        accessObject.Requests,
        accessAction.ViewRegistry,
        true
      ),
    [userProfile.objectAccessActionList]
  );

  const organizationIdField = parent ? `school.${schoolOrganizationIdField}` : schoolOrganizationIdField;
  const organizationNameField = parent ? `school.${schoolOrganizationNameField}` : schoolOrganizationNameField;
  const classParallelIdFieldName = parent ? `school.${classParallelIdField}` : classParallelIdField;
  const classParallelNameFieldName = parent ? `school.${classParallelNameField}` : classParallelNameField;
  const classLetterlIdFieldName = parent ? `school.${classLetterlIdField}` : classLetterlIdField;
  const classLetterlNameFieldName = parent ? `school.${classLetterlNameField}` : classLetterlNameField;

  useEffect(() => {
    const fetchData = async () => {
      const data = await dictionariesApi.getDocumentTypes();

      setDocumentTypes(data);
    };

    fetchData();
  }, []);

  const setValuesByTemplate = async (
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
    item: any
  ): Promise<void> => {
    const findDocument = documentTypes?.find((v) => v.value === item.documentTypeId);

    if (hasSearchOrganization) {
      const contingentData: EducationFact[] = await learnerApi.getEdFacts(item.contingentGuid);

      const { classParallelId, classParallelName, classLetterId, classLetterName, organizationId, organizationName } =
        contingentData[0];

      if (organizationId && organizationName) {
        setFieldValue(organizationIdField, organizationId ?? 0);
        setFieldValue(organizationNameField, organizationName);
      }
      setFieldValue(classParallelIdFieldName, classParallelId);
      setFieldValue(classParallelNameFieldName, classParallelName);
      setFieldValue(classLetterlIdFieldName, classLetterId);
      setFieldValue(classLetterlNameFieldName, classLetterName);
    }

    const documentDayCareOptions = [DocumentTypeEnum.BirthCertificate, DocumentTypeEnum.BirthCertificateForeign];

    const checkDayCareCentersEducation =
      educationTypeData[0].value === EducationTypeEnum.DayCareCentersEducation &&
      !documentDayCareOptions.includes(item.documentTypeId);

    const checkOtherTypeEducation = ![
      ...documentDayCareOptions,
      DocumentTypeEnum.Passport,
      DocumentTypeEnum.ForeignPassport,
      DocumentTypeEnum.BirthRecord,
    ].includes(item.documentTypeId);

    if (checkDayCareCentersEducation || checkOtherTypeEducation) {
      setFieldValue(documentTypeFieldName, 'Свидетельство о рождении');
      setFieldValue(documentTypeField, DocumentTypeEnum.BirthCertificate);
    } else {
      setFieldValue(buildFormFieldName(parent, documentTypeFieldName), findDocument?.label);
      setFieldValue(buildFormFieldName(parent, documentTypeField), item.documentTypeId);
    }

    if (DocumentTypeEnum.BirthRecord === item.documentTypeId) {
      setFieldValue(buildFormFieldName(parent, issuedFieldName), item.documentIssuer);
    }

    setFieldValue(buildFormFieldName(parent, fioLastNameField), item.lastName);
    setFieldValue(buildFormFieldName(parent, fioMiddleNameField), item.middleName);
    setFieldValue(buildFormFieldName(parent, fioFirstNameField), item.firstName);

    setFieldValue(buildFormFieldName(parent, birthDateField), moment(item.birthDate).toDate());

    setFieldValue(buildFormFieldName(parent, genderIdField), item.sexId);
    setFieldValue(buildFormFieldName(parent, docNumberSeriesField), item.documentSeries);
    setFieldValue(buildFormFieldName(parent, docNumberField), item.documentNumber);

    if (item.snils) {
      setFieldValue(
        buildFormFieldName(parent, snilsField),
        item.snils.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1-$2-$3 $4')
      );
    }

    setFieldValue(contingentGuidField, item.contingentGuid);
    setFieldValue(contingentLinkTypeField, ContingentLinkTypeEnum.Found);

    setFieldValue(buildFormFieldName(parent, docNumberDateOfIssueField), moment(item.documentDateIssued).toDate());

    setChildrenList && setChildrenList(null);
    setIsClickedSelect && setIsClickedSelect(true);
  };

  return editMode && childrenList ? (
    <FormikField>
      {({ form }: FormikFieldProps) => {
        const { setFieldValue, setFieldTouched } = form;

        return (
          <div className="table-data__item table-data__group">
            <div className="table-data__label table-data__label--main" />

            {childrenList?.length ? (
              <div className="table-data__body">
                <span className="color-gray-dark">Найдено учащихся:</span>
                <ul className="requests-child-list">
                  {childrenList?.map((c, i) => {
                    return showAllLearners || i < defaultVisibleCount ? (
                      <li
                        // eslint-disable-next-line react/no-array-index-key
                        key={i}
                        className="requests-child-list__item"
                      >
                        <div className="table-data-grid-3 items-start">
                          <div className="flex">
                            <LmIcon
                              icon="filled-account-person"
                              size={20}
                              color="var(--LM-neutrals-day-600)"
                            />
                            <Push
                              orientation="horizontal"
                              size={4}
                            />
                            <div>
                              <div className="font-weight-bold">
                                {c.lastName} {c.firstName} {c.middleName}
                              </div>
                              <Push size={4} />
                              <div className="color-gray-dark">
                                {c.birthDate ? moment(c.birthDate).format('DD.MM.YYYY') : null}
                              </div>
                            </div>
                          </div>
                          <div>
                            {documentTypes?.find((d) => d.value === c.documentTypeId)?.label}
                            <Push size={4} />
                            <div className="color-gray-dark">
                              {c.documentSeries} {c.documentNumber} от{' '}
                              {c.documentDateIssued ? moment(c.documentDateIssued).format('DD.MM.YYYY') : null}
                            </div>
                          </div>
                          <div className="flex justify-end">
                            <button
                              type="button"
                              className="brand-link"
                              onClick={() =>
                                setTimeout(
                                  () =>
                                    setValuesByTemplate((name, value) => {
                                      setFieldValue(name, value);
                                      setFieldTouched(name, true);
                                    }, c),
                                  0
                                )
                              }
                            >
                              Выбрать
                            </button>
                          </div>
                        </div>
                      </li>
                    ) : null;
                  })}
                </ul>
                <Push size={12} />
                {childrenList && childrenList?.length > defaultVisibleCount && (
                  <button
                    type="button"
                    onClick={() => setShowAllLearners(!showAllLearners)}
                    className="icon-group"
                  >
                    <span className="icon-group__text color-primary">
                      {showAllLearners
                        ? 'Скрыть учащихся'
                        : `Других учащихся: ${childrenList.length - defaultVisibleCount}`}
                    </span>
                    <span className="icon-group__icon">
                      {showAllLearners ? (
                        <LmIcon
                          icon="filled-chevrons-small-up"
                          size={20}
                          color="var(--LM-blue-200)"
                        />
                      ) : (
                        <LmIcon
                          icon="filled-chevrons-small-down"
                          size={20}
                          color="var(--LM-blue-200)"
                        />
                      )}
                    </span>
                  </button>
                )}
              </div>
            ) : (
              <div className="table-data__body">
                <LmInfoBox
                  dataTest="learnersNotFoundWarning"
                  className="infobox--full-width"
                  variant="warning"
                  description="Учащиеся не найдены."
                  hidenFooter
                />
              </div>
            )}
          </div>
        );
      }}
    </FormikField>
  ) : null;
};

export default ContingentSearch;

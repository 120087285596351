import React, { useCallback, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { Panel, Push } from '@mosru/esz_uikit';
import { LmIcon } from '@mes-ui/lemma';
import { useDispatch } from 'react-redux';
import SavePanel from '../../../components/save-panel';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import { addPhonePrefix } from '../../../lib/utils/format-number';
import { notify } from '../../../redux/ducks/notifications';
import FIO from '../components/fields/fio';
import BirthDate from '../components/fields/birthdate';
import Phone from '../../../components/fields/phone';
import { PersonRequestData } from '../../../types/requests';
import Email from '../../../components/fields/email';
import Document from '../components/fields/document';
import DocumentNumber from '../components/fields/document-number';
import Issued from '../components/fields/issued';
import { applicantValidationSchema } from './create';
import requestsApi from '../../../lib/api/requests';
import { FormTypeEnum } from '../../../mock-data/form-type-enum';
import { LoaderCustom } from '../../../components/loader-custom';

type Props = {
  requestApplicant: PersonRequestData;
  setEditModeParent: (value: string | null) => void;
  editable: boolean;
  updateRequest: () => void;
};

const ApplicantForm: React.FC<Props> = ({ updateRequest, requestApplicant, setEditModeParent, editable }) => {
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const initialErrors = useInitialErrors(requestApplicant, applicantValidationSchema);
  const [formKey, setFormKey] = useState(Math.random());

  const dispatch = useDispatch();

  const submitForm = useCallback(
    async (values: PersonRequestData) => {
      setLoading(true);
      try {
        values.isChild = false;
        const request = {
          ...values,
          phone: addPhonePrefix(values.phone),
        };

        await requestsApi.saveRequestPerson(request);
        setEditModeParent(null);
        updateRequest();
      } catch (e) {
        setFormKey(Math.random());
        dispatch(
          notify.danger({
            dataTest: 'saveRequestPerson',
            title: 'Не удалось сохранить заявителя',
          })
        );
      } finally {
        setLoading(false);
      }
    },
    [setEditModeParent, updateRequest, dispatch]
  );

  return loading ? (
    <LoaderCustom
      size={180}
      hasPanel
    />
  ) : (
    <Formik
      key={formKey}
      validateOnMount
      enableReinitialize
      onSubmit={submitForm}
      initialErrors={initialErrors}
      initialValues={requestApplicant}
      validationSchema={applicantValidationSchema}
    >
      {(formikProps: FormikProps<PersonRequestData>) => {
        const { handleSubmit, isSubmitting, isValid } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Push size={12} />
            <Panel
              title={() => 'Информация о заявителе'}
              headingControl={() => {
                return !editMode && editable ? (
                  <button
                    type="button"
                    onClick={() => {
                      setEditModeParent('applicant');
                      setEditMode(true);
                    }}
                    className="icon-group"
                  >
                    <span className="icon-group__icon">
                      <LmIcon
                        icon="filled-edit-edit"
                        size={20}
                        color="var(--LM-blue-200)"
                      />
                    </span>
                    <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
                  </button>
                ) : null;
              }}
            >
              <div className="container">
                <div className="table-data">
                  <FIO
                    showErrorImmediately
                    label="ФИО заявителя"
                    required
                    editMode={editMode}
                  />
                  <BirthDate
                    name="dateOfBirth"
                    editMode={editMode}
                  />
                  <Phone
                    showErrorImmediately
                    name="phone"
                    required
                    editMode={editMode}
                  />
                  <Email
                    name="email"
                    editMode={editMode}
                  />
                  <Document
                    name="documentType"
                    editMode={editMode}
                    dependentFields
                    formType={FormTypeEnum.EditRequestApplicant}
                  />
                  <Issued
                    name="issued"
                    editMode={editMode}
                  />
                  <DocumentNumber
                    editMode={editMode}
                    calendarPosition="top-end"
                  />
                </div>
              </div>
            </Panel>

            {editMode && (
              <SavePanel
                primaryButtonModifiers={{
                  loading: isSubmitting,
                  disabled: !isValid,
                }}
                onClickSeconadaryButton={() => {
                  setEditModeParent(null);
                  setEditMode(false);
                  setFormKey(Math.random());
                }}
                onClickPrimaryButton={() => {
                  handleSubmit();
                  setEditModeParent(null);
                  setEditMode(false);
                }}
              />
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default ApplicantForm;

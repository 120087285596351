import React, { useCallback, useEffect, useState } from 'react';
import { LmButton, LmInfoBox } from '@mes-ui/lemma';
import Popup from '../../../../../components/modals/popup';
import contingentApi from '../../../../../lib/api/contingents';
import { numberBirthRecordRegExp } from '../../../../../lib/utils/validation';
import { DocumentTypeEnum } from '../../../../../mock-data/type-document';
import { RequestData } from '../../../../../types/requests';
import { OtherPerson } from './other-person';
import { Error } from './error';
import { MatchTypeEnum } from '../../../../../mock-data/like-modal-enum';
import { useDegree } from '../../../../../lib/utils/like-modal';
import { ErrorPerson, ListPerson } from '../../../../../types/like-modal';
import Person from './person';
import requestsApi from '../../../../../lib/api/requests';
import { redirect } from '../../../../../lib/utils';
import { useGetAppealLinkQuery } from '../../../../../store/home';
import { LoaderCustom } from '../../../../../components/loader-custom';

type Props = {
  open: boolean;
  extendEntityGuid: string;
  updateRequest: () => void;
  close: (value: boolean) => void;
  requestData?: RequestData;
};

const LikeModal = ({ updateRequest, open, close, extendEntityGuid, requestData }: Props) => {
  const [listPerson, setListPerson] = useState<ListPerson[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectLoadingBtn, setSelectLoadingButton] = useState<boolean>(false);
  const [checked, setChecked] = useState<number | null>(null);
  const [loadingBtnNewPerson, setLoadingBtnNewPerson] = useState<boolean>(false);
  const [showSTP, setShowSTP] = useState(false);

  const matchType = useDegree(listPerson);
  const showButtonSTP = matchType === MatchTypeEnum.oneMedium || matchType === MatchTypeEnum.severalWithHigh || showSTP;

  const { data: appealLink = '' } = useGetAppealLinkQuery(undefined, {
    skip: !showButtonSTP,
  });

  const [error, setError] = useState<ErrorPerson>({
    text: '',
    color: '',
    show: false,
  });

  const isShortBirthRecord =
    !!requestData?.child &&
    requestData.child.documentTypeId === DocumentTypeEnum.BirthRecord &&
    !!requestData.child.number &&
    !numberBirthRecordRegExp.test(requestData.child.number.toString());

  const getListPerson = useCallback(async (): Promise<ListPerson[] | undefined> => {
    if (extendEntityGuid) {
      try {
        setLoading(true);
        const response = await contingentApi.getPersonLike(extendEntityGuid);

        setSelectLoadingButton(true);

        if (response.length === 1) {
          setChecked(response[0].person.id);
        }

        setListPerson(
          response.sort((a, b) => {
            const labelA = a.degree;
            const labelB = b.degree;

            if (labelA > labelB) {
              return -1;
            }
            if (labelB < labelA) {
              return 1;
            }

            return 0;
          })
        );

        return response;
      } finally {
        setLoading(false);
      }
    }
  }, [extendEntityGuid]);

  useEffect(() => {
    extendEntityGuid && open && getListPerson();
  }, [extendEntityGuid, getListPerson, open]);

  const newPerson = async (requestId: number | undefined) => {
    setLoadingBtnNewPerson(true);
    if (requestId) {
      const response = await requestsApi.newPerson(requestId);

      if (response.message) {
        setError({
          text: response.message,
          color: 'danger',
          show: true,
        });
        setLoadingBtnNewPerson(false);
        setShowSTP(true);
      } else {
        const listPerson = await getListPerson();

        if (listPerson?.length) {
          setLoadingBtnNewPerson(false);
          setError({
            text: response,
            color: 'success',
            show: true,
          });
          setShowSTP(false);
        }
      }
    }
  };

  const selectPerson = async () => {
    const findPerson = listPerson.find((item) => item.person.id === checked);

    setSelectLoadingButton(true);

    try {
      await contingentApi.updatePerson(extendEntityGuid, findPerson?.person);
      setSelectLoadingButton(false);
      updateRequest();
      close(false);
    } catch (e) {}
  };

  const sendSTP = () => {
    redirect(appealLink);
    close(false);
  };

  const handleClearData = useCallback(() => {
    setListPerson([]);
    setLoading(false);
    setChecked(null);
    setShowSTP(false);
  }, []);

  return (
    <Popup
      dataTest="likePopup"
      open={open}
      title="Проверка сведений об обучающемся"
      size="large"
      onClose={close}
      onClearData={handleClearData}
      hidePrimaryButton
      hideSecondaryButton
      additionalButtons={
        <>
          <LmButton
            type="button"
            variant="secondary"
            onClick={() => close(false)}
          >
            Отмена
          </LmButton>

          {!loading ? (
            <>
              {showButtonSTP && (
                <LmButton
                  type="button"
                  variant="outline"
                  onClick={sendSTP}
                >
                  Обратиться в СТП
                </LmButton>
              )}
              {(matchType === MatchTypeEnum.oneLow ||
                matchType === MatchTypeEnum.several ||
                matchType === MatchTypeEnum.noMatch) && (
                <LmButton
                  type="button"
                  variant={matchType !== MatchTypeEnum.noMatch ? 'outline' : 'primary'}
                  loading={loadingBtnNewPerson}
                  disabled={isShortBirthRecord}
                  onClick={() => newPerson(requestData?.id)}
                >
                  Создать новую персону
                </LmButton>
              )}
              {matchType !== MatchTypeEnum.noMatch && (
                <LmButton
                  type="button"
                  disabled={!checked}
                  loading={!selectLoadingBtn}
                  onClick={selectPerson}
                >
                  Выбрать
                </LmButton>
              )}
            </>
          ) : null}
        </>
      }
    >
      <div className="requests-check-details">
        <div className="requests-check-details__item">
          <Person requestData={requestData} />

          <div className="requests-check-details__inner line-height-text">
            <Error {...error} />

            {!loading && !error.text
              ? matchType === MatchTypeEnum.oneLow
                ? 'По указанным Вами данным найдена похожая персона:'
                : matchType === MatchTypeEnum.oneMedium
                  ? 'Указанные Вами данные частично совпадают с данными обучающегося в Реестре контингента. Вы можете использовать данные найденной персоны, нажав на кнопку “Выбрать”.'
                  : matchType === MatchTypeEnum.oneHigh
                    ? 'Данные обучающегося успешно найдены в Реестре контингента:'
                    : matchType === MatchTypeEnum.several
                      ? 'По указанным Вами данным найдено несколько похожих персон:'
                      : matchType === MatchTypeEnum.severalWithHigh
                        ? 'По указанным Вами данным найдено соответствие с несколькими персонами:'
                        : !isShortBirthRecord &&
                  'По указанным Вами данным похожие персоны не найдены. Для создания новой персоны нажмите кнопку "Создать новую персону”.'
              : null}
          </div>
        </div>
        {!loading ? (
          listPerson.length ? (
            listPerson.map((item: ListPerson) => {
              return (
                <OtherPerson
                  checked={checked}
                  id={item.person.id}
                  key={item.person.id}
                  degree={item.degree}
                  setChecked={setChecked}
                  snils={item.person.snils}
                  lastname={item.person.lastname}
                  gender_id={item.person.gender_id}
                  firstname={item.person.firstname}
                  birthdate={item.person.birthdate}
                  patronymic={item.person.patronymic}
                  documents_react={item.person.documents_react}
                  documentTypeId={requestData?.child.documentTypeId}
                  documentTypeName={requestData?.child.documentTypeName}
                />
              );
            })
          ) : (
            isShortBirthRecord && (
              <div className="container">
                <LmInfoBox
                  dataTest="emptyListAndShortNumberAlert"
                  variant="alert"
                  className="infobox--full-width"
                  description="По указанным Вами данным похожие персоны не найдены. Выполните отказ в зачислении и сообщите заявителю о необходимости подачи заявления по свидетельству о рождении или с 21-разрядным номером записи о рождении в ЕГР ЗАГС"
                  hidenFooter
                />
              </div>
            )
          )
        ) : (
          <LoaderCustom size={40} />
        )}

        {!loading
          ? (matchType === MatchTypeEnum.oneLow ||
              matchType === MatchTypeEnum.oneMedium ||
              matchType === MatchTypeEnum.several ||
              matchType === MatchTypeEnum.severalWithHigh) && (
              <div className="requests-check-details__item">
                <div className="requests-check-details__inner line-height-text">
                  {matchType === MatchTypeEnum.oneLow
                    ? 'Для использования найденной персоны нажмите кнопку “Выбрать”. Для создания новой персоны нажмите кнопку “Создать новую персону”.'
                    : matchType === MatchTypeEnum.oneMedium
                      ? 'Если Вы уверены, что найденные данные содержат ошибку, то обратитесь в СТП. Создать нового обучающегося с указанными данными невозможно.'
                      : matchType === MatchTypeEnum.several
                        ? 'Выберите одну из найденных персон и нажмите кнопку “Выбрать”.  Для создания новой персоны нажмите кнопку “Создать новую персону”.'
                        : matchType === MatchTypeEnum.severalWithHigh
                          ? 'Если Вы уверены, что найденные данные содержат ошибку, то обратитесь в СТП. Создать нового обучающегося с указанными данными невозможно.'
                          : ''}
                </div>
              </div>
            )
          : null}
      </div>
    </Popup>
  );
};

export default LikeModal;

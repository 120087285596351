import React, { useCallback, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { Panel, Push } from '@mosru/esz_uikit';
import { LmIcon, LmLoader } from '@mes-ui/lemma';
import SavePanel from '../../../components/save-panel';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import { addPhonePrefix } from '../../../lib/utils/format-number';
import { PersonRequestData } from '../../../types/requests';
import Issued from '../components/fields/issued';
import { submitPersonRequestData } from '../utils';
import FIO from '../components/fields/fio';
import BirthDate from '../components/fields/birthdate';
import Phone from '../../../components/fields/phone';
import Email from '../../../components/fields/email';
import Document from '../components/fields/document';
import DocumentNumber from '../components/fields/document-number';
import { applicantValidationSchema } from './create';
import { FormTypeEnum } from '../../../mock-data/form-type-enum';

type ApplicantFormProps = {
  setEditModeParent: (value: string | null) => void;
  requestApplicant: PersonRequestData;
  editable?: boolean;
  updateRequest: () => void;
};

const ApplicantForm: React.FC<ApplicantFormProps> = ({
  setEditModeParent,
  requestApplicant,
  editable,
  updateRequest,
}) => {
  const [editMode, setEditMode] = useState(false);
  const initialErrors = useInitialErrors(requestApplicant, applicantValidationSchema);
  const [loading, setLoading] = useState(false);

  const submitForm = useCallback(
    async (values: PersonRequestData) => {
      const request = {
        ...values,
        phone: addPhonePrefix(values.phone),
      };

      await submitPersonRequestData(request, false, setLoading, setEditMode, setEditModeParent, updateRequest);
    },
    [setEditModeParent, updateRequest]
  );

  return loading ? (
    <div className="loader-container">
      <LmLoader
        view="page"
        title="Идет сохранение! "
        description="Пожалуйста, подождите..."
      />
    </div>
  ) : (
    <Formik
      initialErrors={initialErrors}
      validationSchema={applicantValidationSchema}
      onSubmit={submitForm}
      enableReinitialize
      initialValues={requestApplicant}
    >
      {(formikProps: FormikProps<PersonRequestData>) => {
        const { handleSubmit, submitForm, values, isSubmitting, isValid, resetForm } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Push size={12} />
            <Panel
              title={() => 'Информация о заявителе'}
              headingControl={() => {
                return !editMode && editable ? (
                  <button
                    type="button"
                    onClick={() => {
                      setEditModeParent('applicant');
                      setEditMode(true);
                    }}
                    className="icon-group"
                  >
                    <span className="icon-group__icon">
                      <LmIcon
                        icon="filled-edit-edit"
                        size={20}
                        color="var(--LM-blue-200)"
                      />
                    </span>
                    <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
                  </button>
                ) : null;
              }}
            >
              <div className="container">
                <div className="table-data">
                  <FIO
                    label="ФИО заявителя"
                    required
                    editMode={editMode}
                    value={{
                      firstName: values.firstName,
                      lastName: values.lastName,
                      middleName: values.middleName,
                    }}
                  />
                  <BirthDate
                    name="dateOfBirth"
                    editMode={editMode}
                  />
                  <Phone
                    name="phone"
                    editMode={editMode}
                    value={values.phone}
                    required
                  />
                  <Email
                    name="email"
                    editMode={editMode}
                    value={values.email}
                  />
                  <Document
                    name="documentType"
                    editMode={editMode}
                    dependentFields
                    formType={FormTypeEnum.EditRequestApplicant}
                  />
                  <Issued
                    name="issued"
                    editMode={editMode}
                  />
                  <DocumentNumber
                    editMode={editMode}
                    calendarPosition="top-end"
                  />
                </div>
              </div>
            </Panel>

            {editMode && (
              <SavePanel
                primaryButtonModifiers={{
                  loading: isSubmitting,
                  disabled: !isValid,
                }}
                onClickSeconadaryButton={() => {
                  setEditModeParent(null);
                  setEditMode(false);
                  resetForm();
                }}
                onClickPrimaryButton={submitForm}
              />
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default ApplicantForm;

import React, { useCallback, useContext, useMemo, useState } from 'react';
import { object as objectYup, string as stringYup } from 'yup';
import { Formik, FormikProps } from 'formik';
import { Push } from '@mosru/esz_uikit';
import { LmButton } from '@mes-ui/lemma';
import FormikDatePicker from '../../../components/formik/formik-datepicker';
import FormikInput from '../../../components/formik/formik-input';
import { RequestStatusEnum } from '../../../mock-data/request-status-enum';
import { DocumentTypeEnum } from '../../../mock-data/type-document';
import { SelectOptionType } from '../../../types/entities';
import Document from '../components/fields/document';
import { FormTypeEnum } from '../../../mock-data/form-type-enum';
import SimpleTextArea from '../../../components/fields/simple-textarea';
import { RequestData, RequestEditServiceClassData, RequestEnrollmentData } from '../../../types/requests';
import ShortNumberInfobox from '../components/short-number-infobox';
import { submitEnrollment } from '../utils';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import { docDate } from '../../../lib/utils/validation';
import LikeModal from '../components/like/modal';
import { RequestContext } from '../../../lib/utils/requests';
import { EnrollmentModal } from '../components/modals';
import { InfoLike } from '../components/like/info';
import { LoaderCustom } from '../../../components/loader-custom';
import { Statuses } from './statuses';

type Props = {
  requestId: number;
  setEditMode: () => void;
  updateRequest: () => void;
  extendEntityGuid: string;
  requestData?: RequestData;
};

const ActionsFormEnrollment: React.FC<Props> = ({
  requestData,
  extendEntityGuid,
  setEditMode,
  updateRequest,
  requestId,
}) => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const enrollment = requestData?.enrollment;
  const [enrollmentDisabled, setEnrollmentDisabled] = useState(true);

  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const { canEditRequestDOGM } = useContext(RequestContext);

  const submitForm = useCallback(
    async (values: RequestEnrollmentData) => {
      await submitEnrollment(
        {
          ...values,
          serviceClass: {
            id: requestData?.trainingGroup?.serviceClass?.id || 0,
          } as RequestEditServiceClassData,
        },
        requestId,
        setLoading,
        setShowModal,
        setEditMode,
        updateRequest
      );
    },
    [requestData?.trainingGroup?.serviceClass?.id, requestId, setEditMode, updateRequest]
  );

  const initialData = useMemo(() => {
    return {
      ...enrollment,
      enrollDocumentTypeId: 3,
      contingentGuid: requestData?.contingentGuid,
    } as RequestEnrollmentData;
  }, [enrollment, requestData?.contingentGuid]);

  const initialErrors = useInitialErrors(initialData, getValidationSchema());

  return loading ? (
    <LoaderCustom
      size={180}
      hasPanel
    />
  ) : (
    <Formik
      validateOnMount
      enableReinitialize
      onSubmit={submitForm}
      initialValues={initialData}
      initialErrors={initialErrors}
      validationSchema={getValidationSchema()}
    >
      {(formikProps: FormikProps<RequestEnrollmentData>) => {
        const { handleSubmit, isSubmitting, isValid, setFieldValue, setFieldTouched, resetForm } = formikProps;

        return (
          <>
            <form onSubmit={handleSubmit}>
              <ShortNumberInfobox child={requestData?.child} />

              <InfoLike
                requestData={requestData}
                setOpen={setOpenPopup}
                disabled={!canEditRequestDOGM}
              />

              <Statuses
                contingentGuid={requestData?.contingentGuid}
                serviceClassId={requestData?.trainingGroup?.serviceClass?.id}
                cheked={() => setEnrollmentDisabled(false)}
              />
              <div className="container">
                <Push size={20} />
                <div className="table-data">
                  <div className="table-data__item table-data__group">
                    <div className="table-data__label table-data__label--main">
                      Дата зачисления <div className="table-data__required" />
                    </div>
                    <div className="table-data__body">
                      <div className="table-data-grid-3">
                        <FormikDatePicker
                          placeholder={!enrollmentDisabled ? 'ДД.ММ.ГГГГ' : ''}
                          size="small"
                          name="enrollDate"
                          disabled={enrollmentDisabled}
                        />
                      </div>
                    </div>
                  </div>
                  <Document
                    size={3}
                    name="enrollDocumentType"
                    label="Вид документа-основания"
                    editMode
                    formType={FormTypeEnum.EditRequestEnrollment}
                    required
                    disabled={enrollmentDisabled}
                    onChangeDocument={(value?: SelectOptionType) => {
                      if (requestData?.requestStatusId === RequestStatusEnum.AcceptedElectronically) {
                        const isContract = value?.value === DocumentTypeEnum.Contract;

                        setFieldTouched('enrollDocNumber', true, false);
                        setFieldTouched('enrollDocDate', true, false);
                        setFieldValue('enrollDocNumber', isContract ? requestData?.contract?.contractNumber : null);
                        setFieldValue(
                          'enrollDocDate',
                          isContract ? requestData?.contract?.contractStatusDate || undefined : undefined
                        );
                      }
                    }}
                  />
                  <div className="table-data__item table-data__group">
                    <div className="table-data__label table-data__label--main">
                      Номер и дата документа <div className="table-data__required" />
                    </div>
                    <div className="table-data__body">
                      <div className="table-data-grid-2">
                        <FormikInput
                          size="small"
                          name="enrollDocNumber"
                          placeholder={!enrollmentDisabled ? 'Введите номер...' : ''}
                          disabled={enrollmentDisabled}
                        />
                        <FormikDatePicker
                          size="small"
                          name="enrollDocDate"
                          placeholder={!enrollmentDisabled ? 'ДД.ММ.ГГГГ' : ''}
                          disabled={enrollmentDisabled}
                        />
                      </div>
                    </div>
                  </div>
                  <SimpleTextArea
                    disabled={enrollmentDisabled}
                    name="enrollComment"
                    placeholder={!enrollmentDisabled ? 'Введите...' : ''}
                    editMode
                    label="Комментарий"
                  />
                </div>
              </div>
              <div className="requests-hr" />
              <div className="container">
                <div className="flex justify-end">
                  <LmButton
                    type="button"
                    variant="outline"
                    size="medium"
                    onClick={() => resetForm()}
                  >
                    Сбросить
                  </LmButton>
                  <Push
                    size={12}
                    orientation="horizontal"
                  />
                  <LmButton
                    type="button"
                    size="medium"
                    disabled={!isValid || isSubmitting || !requestData?.contingentGuid}
                    onClick={() => setShowModal(true)}
                  >
                    Зачислить
                  </LmButton>
                </div>
                <Push size={16} />
              </div>
              <EnrollmentModal
                showModal={showModal}
                setShowModal={setShowModal}
                handleSubmit={handleSubmit}
              />
            </form>

            <LikeModal
              open={openPopup}
              close={setOpenPopup}
              requestData={requestData}
              updateRequest={updateRequest}
              extendEntityGuid={extendEntityGuid}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default ActionsFormEnrollment;

const getValidationSchema = () =>
  objectYup().shape({
    contingentGuid: stringYup().required(),
    enrollDate: docDate.required('Выберите дату зачисления').nullable(),
    enrollDocumentTypeId: stringYup().required('Выберите тип документа').nullable(),
    enrollDocNumber: stringYup().required('Введите номер документа').nullable(),
    enrollDocDate: docDate.required('Выберите дату документа').nullable(),
  });

import { TypeSwitch } from '../../types/reports';
import { TrainingGroupItemType } from '../../types/service-class';
import { fetchRequest } from './index';
import { apiService } from '../../config/constants';
import { TrainingGroupByServiceDonm, TrainingGroupSCBooking } from '../../types/requests';
import {
  ArchiveObstacle,
  CandidateRestrictionTypeListData,
  ContactPersonData,
  DisciplineData,
  EditServiceStatusData,
  EditTrainingGroupStatusData,
  EditTrainingScheduleStatusData,
  EntranceTestsData,
  FinancingData,
  ItemWorkData,
  ItemWorkList,
  PlaceServiceData,
  PlaceServiceList,
  ScheduleData,
  SearchInitialFormData,
  SearchItem,
  ServiceData,
  ServiceDataInfo,
  ServiceEditTextFileData,
  ServiceScheduleData,
  SignServiceData,
  SignTrainingGroupData,
  SignTrainingScheduleData,
  StageList,
  TrainingGroupData,
} from '../../types/service';
import { ExportFileData } from '../../types';
import { ProgrammModule, SelectOptionType } from '../../types/entities';
import { EducationTypeEnum } from '../../types/education-type';
import { sendReachGoal } from '../metrica';

export const postServiceTemplate = async (
  data: SearchInitialFormData
): Promise<{
  total: number;
  items: SearchItem[];
}> => {
  sendReachGoal(`service-search-${data.educationTypeId}`);

  return fetchRequest.post(`${apiService.data}/Service/Search`, data);
};

export const getServiceExport = async (data: SearchInitialFormData): Promise<ExportFileData> => {
  return await fetchRequest.post(`${apiService.data}/Service/ToExcel`, data);
};

export const getTypesFinance = async (): Promise<TypeSwitch[]> => {
  const typeFinance = await fetchRequest.get(`${apiService.data}/Service/Types`);
  const result = typeFinance.data.map((item: { id: number; name: string }) => {
    return {
      id: item.id,
      name: item.name,
      selected: false,
    };
  });

  return await result;
};

export const getListLevelProgramm = async (data: { accObj: number }): Promise<SelectOptionType[]> => {
  const programms = await fetchRequest.get(`${apiService.data}/Service/ProgramLevels/ByUser`, data);

  const result = programms.data.map((item: { id: number; name: string }) => {
    return {
      label: item.name,
      value: item.id,
    };
  });

  return await result;
};

export const getListLevelProgrammByOrganization = async (data: { accObj: number }): Promise<SelectOptionType[]> => {
  const programms = await fetchRequest.get(`${apiService.data}/Service/ProgramLevels/ByOrganization`, data);

  const result = programms.data.map((item: { id: number; name: string }) => {
    return {
      label: item.name,
      value: item.id,
    };
  });

  return await result;
};

export const getStatusService = async (): Promise<SelectOptionType[]> => {
  const statuses = await fetchRequest.get(`${apiService.data}/Service/Statuses`);

  const result = statuses.data.map((item: { id: number; name: string }) => {
    return {
      label: item.name,
      value: item.id,
    };
  });

  return await result;
};

export const getTrainingGroups = async (data: { serviceId: number }): Promise<SelectOptionType[]> => {
  const trainGroups = await fetchRequest.get(
    `${apiService.data}/Service/${data.serviceId}/TrainingGroup/Request/TgBooking`,
    {
      onlySigned: false,
      count: 20,
    }
  );
  const result = trainGroups.map((item: { id: number; name: string }) => {
    return {
      label: item.name,
      value: item.id,
    };
  });

  return await result;
};

export const getTrainingGroupsServiceClass = async (data: {
  serviceId: number;
  programmModuleId?: number;
}): Promise<(SelectOptionType & TrainingGroupItemType)[]> => {
  const trainGroups = await fetchRequest.get(
    `${apiService.data}/Service/${data.serviceId}/TrainingGroup/ServiceClass`,
    {
      count: 20,
      programmModuleId: data.programmModuleId,
    }
  );
  const result = trainGroups.map(
    (
      item: TrainingGroupItemType & {
        id: number;
        name: string;
      }
    ) => {
      const { name, id, requestStartDate, requestEndDate, educationDateStart, educationDateEnd, volume, groupVolume } =
        item;

      return {
        label: name,
        value: id,
        requestStartDate,
        requestEndDate,
        educationDateStart,
        educationDateEnd,
        volume,
        groupVolume,
      };
    }
  );

  return await result;
};

export const getPlaceServices = async (data: {
  serviceId: number | string;
  withOrgServicePlaces?: boolean;
  isOnlyActual?: boolean;
  count?: number;
}): Promise<PlaceServiceData[]> =>
  await fetchRequest.get(`${apiService.data}/Service/${data.serviceId}/PlaceService/List`, {
    withOrgServicePlaces: data.withOrgServicePlaces,
    isOnlyActual: data.isOnlyActual,
    count: data.count,
  });

export const updatePlaceServiceList = async (serviceId: number, items: PlaceServiceList) =>
  await fetchRequest.patch(`${apiService.data}/Service/${serviceId}/PlaceService`, items);

export const getPlaceServiceAddresses = async (data: { serviceId: number }): Promise<SelectOptionType[]> => {
  const placeServices = await fetchRequest.get(`${apiService.data}/Service/${data.serviceId}/Address/List`, {
    isOnlyActual: true,
    count: 20,
  });
  const result = placeServices.map((item: { id: number; name: string; fullAddress: string }) => {
    return {
      label: item.fullAddress,
      value: item.id,
    };
  });

  return await result;
};

export const getProgrammLevels = async (data: { classificatorEKUId: number }): Promise<SelectOptionType[]> => {
  const programmLevelsList = await fetchRequest.get(`${apiService.data}/Classificator/ProgrammLevel/`, {
    classificatorEKUId: data.classificatorEKUId,
  });

  return programmLevelsList.map((item: { id: number; name: string }) => {
    return {
      value: item.id,
      label: item.name,
    };
  });
};

export const getTrainingGroupsByService = async (serviceId: number): Promise<TrainingGroupByServiceDonm[]> => {
  return await fetchRequest.get(`${apiService.data}/Service/${serviceId}/TrainingGroup/Request/TgBooking`, {
    onlySigned: true,
    count: 20,
  });
};

export const getTrainingGroupsSCBooking = async (serviceId: number): Promise<TrainingGroupSCBooking[]> => {
  return await fetchRequest.get(`${apiService.data}/Service/${serviceId}/TrainingGroup/Request/ScBooking`, {
    onlySigned: true,
    count: 20,
  });
};

export const getListProgramModule = async (data: { serviceId: number }): Promise<SelectOptionType[]> => {
  const list = await fetchRequest.get(`${apiService.data}/Service/${data.serviceId}/ProgrammModule/List`);

  return list.map((item: { id: number; name: string }) => {
    return {
      value: item.id,
      label: item.name,
    };
  });
};

export const setSignedData = async (id: number, data: SignServiceData) =>
  await fetchRequest.patch(`${apiService.data}/Service/${id}/Sign`, data);

export const signByIds = async (ids: number[], data: SignServiceData) =>
  await fetchRequest.patch(`${apiService.data}/Sign/List`, {
    ...data,
    serviceIds: ids,
  });

export const getListServiceStage = async (serviceId: number): Promise<SelectOptionType[]> => {
  const list = await fetchRequest.get(`${apiService.data}/Service/${serviceId}/ServiceStage/List`);

  return list.map((item: { id: number; name: string }) => {
    return {
      value: item.id,
      label: item.name,
    };
  });
};

export const getServiceName = async (serviceId: number | string): Promise<string> =>
  await fetchRequest.get(`${apiService.data}/Service/${serviceId}/Name`);

export const getServiceById = async (serviceId: number | string): Promise<ServiceData> => {
  const data = await fetchRequest.get(`${apiService.data}/Service/${serviceId}`);

  return {
    ...data,
    parallelList: data?.parallelList?.map((item: { id: number; parallelName: string }) => ({
      value: item.id,
      label: item.parallelName,
    })),
  };
};

export const getServiceAvalableStageList = async (serviceId: number, data: number[]): Promise<SelectOptionType[]> => {
  const list = await fetchRequest.post(`${apiService.data}/Service/${serviceId}/ServiceStage/AvalableStageList`, data);

  return list.map((item: { id: number; name: string; checked: boolean }) => {
    return {
      value: item.id,
      label: item.name,
      isDisabled: !item.checked,
    };
  });
};

export const getServiceTrainingGroupList = async (serviceId: number | string): Promise<TrainingGroupData[]> =>
  await fetchRequest.get(`${apiService.data}/Service/${serviceId}/TrainingGroup/List`);

// TODO: проверить адресс
export const serviceToArchive = async (serviceId: string): Promise<any> => {
  return fetchRequest.delete(`${apiService.data}/Service/${serviceId}`);
};

export const getServiceItemWorkList = async (data: {
  serviceId: number;
  withOrgServicePlaces?: boolean;
  count?: number;
}): Promise<ItemWorkData[]> =>
  await fetchRequest.get(`${apiService.data}/Service/${data.serviceId}/ItemWork/List`, {
    withOrgServicePlaces: data.withOrgServicePlaces,
    isOnlyActual: true,
    count: data.count,
  });

export const updateServiceItemWorkList = async (serviceId: number, items: ItemWorkList) =>
  await fetchRequest.patch(`${apiService.data}/Service/${serviceId}/ItemWork`, items);

export const updateContactPerson = async (serviceId: number, data: ContactPersonData) => {
  return await fetchRequest.patch(`${apiService.data}/Service/${serviceId}/ContactPerson`, data);
};

export const updateService = async (serviceId: number, serviceInfo: ServiceDataInfo) =>
  await fetchRequest.patch(`${apiService.data}/Service/${serviceId}`, serviceInfo);

export const updateFinancing = async (serviceId: number, data: FinancingData) =>
  await fetchRequest.patch(`${apiService.data}/Service/${serviceId}/Financing`, data);

// TODO: поправить запрос
export const getProgrammModule = async (moduleId: string | number, serviceId: number): Promise<ProgrammModule> => {
  return fetchRequest.get(`${apiService.data}/Service/${serviceId}/ProgrammModule/${moduleId}`);
};

export const updateServiceSchedule = async (serviceId: number, serviceSchedule: ServiceScheduleData) =>
  await fetchRequest.patch(`${apiService.data}/Service/${serviceId}/Schedule`, serviceSchedule);

export const createTrainingGroup = async (serviceId: number, tg: TrainingGroupData) =>
  await fetchRequest.post(`${apiService.data}/Service/${serviceId}/TrainingGroup`, {
    ...tg,
    serviceId,
  });

export const updateTrainingGroup = async (serviceId: number, tg: TrainingGroupData) =>
  await fetchRequest.put(`${apiService.data}/Service/${serviceId}/TrainingGroup/${tg.id}`, {
    ...tg,
    serviceId,
  });

export const createTrainingGroupNonDogm = async (serviceId: number, tg: TrainingGroupData) =>
  await fetchRequest.post(`${apiService.data}/Service/${serviceId}/TrainingGroup/NonDogm`, {
    ...tg,
    serviceId,
  });

export const updateTrainingGroupNonDogm = async (serviceId: number, tg: TrainingGroupData) =>
  await fetchRequest.put(`${apiService.data}/Service/${serviceId}/TrainingGroup/NonDogm/${tg.id}`, {
    ...tg,
    serviceId,
  });

export const updateStages = async (serviceId: number, data: StageList) =>
  await fetchRequest.patch(`${apiService.data}/Service/${serviceId}/ServiceStage`, {
    ...data,
    serviceId,
  });

export const updateModule = async (serviceId: number, moduleId: number, data: ProgrammModule) =>
  await fetchRequest.put(`${apiService.data}/Service/${serviceId}/ProgrammModule/${moduleId}`, data);

export const createModule = async (serviceId: number, data: ProgrammModule) =>
  await fetchRequest.post(`${apiService.data}/Service/${serviceId}/ProgrammModule`, data);

export const deleteModule = async (serviceId: number, moduleId: number) =>
  await fetchRequest.delete(`${apiService.data}/Service/${serviceId}/ProgrammModule/${moduleId}`);

export const createDiscipline = async (
  serviceId: number,
  educationType: EducationTypeEnum,
  data: DisciplineData
): Promise<number> =>
  await fetchRequest.post(`${apiService.data}/Service/${serviceId}/Discipline`, {
    ...data,
    educationTypeId: educationType,
    serviceId,
  });

export const updateDiscipline = async (serviceId: number, educationType: EducationTypeEnum, data: DisciplineData) =>
  await fetchRequest.put(`${apiService.data}/Service/${serviceId}/Discipline/${data.id}`, {
    ...data,
    educationTypeId: educationType,
    serviceId,
  });

export const deleteDiscipline = async (serviceId: number, disciplineId: number) =>
  await fetchRequest.delete(`${apiService.data}/Service/${serviceId}/Discipline/${disciplineId}`);

export const updateTest = async (serviceId: number, data: EntranceTestsData) =>
  await fetchRequest.patch(`${apiService.data}/Service/${serviceId}/test`, {
    ...data,
    serviceId,
  });

export const getAdmissionPlanList = async (serviceId: number): Promise<SelectOptionType[]> => {
  const list = await fetchRequest.get(`${apiService.data}/Service/${serviceId}/AdmissionPlan`);

  return list.map((item: { yearOfTrainingId: number; yearOfTrainingName: string }) => {
    return {
      value: item.yearOfTrainingId,
      label: item.yearOfTrainingName,
    };
  });
};

export const createService = async (value: ServiceData) => await fetchRequest.post(`${apiService.data}/Service`, value);

export const getTrainingGroup = async (id: number | string, onlyActual = false): Promise<TrainingGroupData> =>
  await fetchRequest.get(`${apiService.data}/Service/TrainingGroup/${id}?onlyActual=${onlyActual}`);

export const getTrainingSchedule = async (id: number | string, onlyActual = false): Promise<ScheduleData> =>
  await fetchRequest.get(`${apiService.data}/Service/TrainingSchedule/${id}?onlyActual=${onlyActual}`);

export const getEmptyService = async (edType: string | number): Promise<ServiceData> =>
  await fetchRequest.get(`${apiService.data}/Service/Empty/${edType}`);

export const saveTrainingSchedule = async (serviceId: number | string, schedule: ScheduleData) =>
  await fetchRequest.post(`${apiService.data}/Service/${serviceId}/TrainingSchedule`, schedule);

export const updateTrainingSchedule = async (serviceId: number | string, schedule: ScheduleData): Promise<number> =>
  await fetchRequest.put(`${apiService.data}/Service/${serviceId}/TrainingSchedule/${schedule.id}`, schedule);

export const getServiceByTemplate = async (templateId: string | number): Promise<ServiceData> =>
  await fetchRequest.get(`${apiService.data}/Template/${templateId}/Service`);

export const updateServiceStatus = async (data: EditServiceStatusData) =>
  await fetchRequest.patch(`${apiService.data}/Service/${data.serviceId}/Status`, data);

export const updateTrainingGroupStatus = async (data: EditTrainingGroupStatusData) =>
  await fetchRequest.patch(
    `${apiService.data}/Service/${data.serviceId}/TrainingGroup/${data.trainingGroupId}/Status`,
    data
  );

export const updateTrainingScheduleStatus = async (data: EditTrainingScheduleStatusData) =>
  await fetchRequest.patch(
    `${apiService.data}/Service/${data.serviceId}/TrainingSchedule/${data.scheduleOfTimetableId}/Status`,
    data
  );

export const deleteTrainingGroup = async (serviceId: number | string, trainingGroupId: number) =>
  fetchRequest.delete(`${apiService.data}/Service/${serviceId}/TrainingGroup/${trainingGroupId}`);

export const deleteTrainingSchedule = async (serviceId: number | string, trainingScheduleId: number) =>
  fetchRequest.delete(`${apiService.data}/Service/${serviceId}/TrainingSchedule/${trainingScheduleId}`);

export const signTrainingGroup = async (data: SignTrainingGroupData) =>
  await fetchRequest.patch(
    `${apiService.data}/Service/${data.serviceId}/TrainingGroup/${data.trainingGroupId}/Sign`,
    data
  );

export const signTrainingSchedule = async (data: SignTrainingScheduleData) =>
  await fetchRequest.patch(
    `${apiService.data}/Service/${data.serviceId}/TrainingSchedule/${data.scheduleOfTimetableId}/Sign`,
    data
  );

export const copyAdmissionPlansFromTeplate = async (serviceId: number) =>
  await fetchRequest.patch(`${apiService.data}/Service/${serviceId}/AdmissionPlan`, {});

export const setCandidateRestriction = async (data: CandidateRestrictionTypeListData) =>
  await fetchRequest.patch(`${apiService.data}/Service/${data.serviceId}/CandidateRestriction`, data);

export const getArchiveObstacle = async (serviceId: number): Promise<ArchiveObstacle> => {
  return await fetchRequest.get(`${apiService.data}/Service/${serviceId}/ArchiveObstacle`);
};

export const getDayCareAbility = async (): Promise<null | { errorCode: number; message: string }> => {
  return await fetchRequest.get(`${apiService.data}/Service/DayCareAbility`);
};

export const setTextFiles = async (data: ServiceEditTextFileData) => {
  const formData = new FormData();

  formData.append('serviceId', (data.serviceId ?? 0).toString());
  formData.append('EducationTypeId', (data.educationTypeId ?? 0).toString());
  formData.append('isProgrammServiceDeleted', data.isProgrammServiceDeleted ? 'true' : 'false');
  formData.append('isRuleServiceDeleted', data.isRuleServiceDeleted ? 'true' : 'false');
  if (data.programmService) {
    formData.append('programmService', data.programmService);
  }
  if (data.ruleService) {
    formData.append('ruleService', data.ruleService);
  }

  return await fetchRequest.putFormData(`${apiService.data}/Service/${data.serviceId}/TextFile`, formData);
};

export const getServiceFile = async (serviceId: number, fileId: number | string) =>
  await fetchRequest.get(`${apiService.data}/Service/${serviceId}/TextFile/${fileId}`);

export const updateByClassifier = async (serviceId: number) =>
  await fetchRequest.patch(`${apiService.data}/Service/${serviceId}/Discipline/Classificator`, {});

export const copyService = async (serviceId: number) =>
  await fetchRequest.post(`${apiService.data}/Service/${serviceId}/copy`, {});

export const serviceTemplateApi = {
  postServiceTemplate,
  getTypesFinance,
  getStatusService,
  getListLevelProgramm,
  getListLevelProgrammByOrganization,
  getTrainingGroups,
  getPlaceServices,
  getProgrammLevels,
  getTrainingGroupsByService,
  getListProgramModule,
  getTrainingGroupsSCBooking,
  getServiceExport,
  setSignedData,
  signByIds,
  getListServiceStage,
  getPlaceServiceAddresses,
  updatePlaceServiceList,
  getTrainingGroupsServiceClass,
  getServiceName,
  getServiceById,
  getServiceAvalableStageList,
  getServiceTrainingGroupList,
  serviceToArchive,
  getServiceItemWorkList,
  updateServiceItemWorkList,
  updateContactPerson,
  updateService,
  updateFinancing,
  getProgrammModule,
  updateServiceSchedule,
  createTrainingGroup,
  updateTrainingGroup,
  createTrainingGroupNonDogm,
  updateTrainingGroupNonDogm,
  updateStages,
  updateModule,
  createModule,
  deleteModule,
  createDiscipline,
  updateDiscipline,
  deleteDiscipline,
  updateTest,
  getAdmissionPlanList,
  createService,
  getTrainingGroup,
  getTrainingSchedule,
  getEmptyService,
  saveTrainingSchedule,
  updateTrainingSchedule,
  getServiceByTemplate,
  updateServiceStatus,
  updateTrainingGroupStatus,
  updateTrainingScheduleStatus,
  deleteTrainingGroup,
  deleteTrainingSchedule,
  signTrainingGroup,
  signTrainingSchedule,
  copyAdmissionPlansFromTeplate,
  setCandidateRestriction,
  getArchiveObstacle,
  getDayCareAbility,
  setTextFiles,
  getServiceFile,
  updateByClassifier,
  copyService,
};

import { useField } from 'formik';
import { useEffect, useState } from 'react';
import FormikSelect from '../../../../components/formik/formik-select';
import Field, { FieldProps } from '../../../../components/fields/field';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { EducationTypeEnum } from '../../../../types/education-type';
import { TrainingGroupItemType } from '../../../../types/service-class';
import { moduleFieldId } from './module';
import { serviceIdField } from './service';
import { SelectOptionType } from '../../../../types/entities';

type TrainingGroupProps = FieldProps<string> & {
  setCurrentTrainingGroup?: (data: TrainingGroupItemType) => void;
  educationType?: number;
};

export const trainingGroupFieldId = 'trainingGroupId';
export const trainingGroupFieldName = 'trainingGroupName';

const TrainingGroupField = ({
  label = 'План приема',
  name,
  editMode,
  required,
  defaultValue,
  disabled,
  setCurrentTrainingGroup,
  educationType,
}: TrainingGroupProps) => {
  const fieldName = `${name}Name`;
  const fieldIdName = `${name}Id`;

  const [serviceField] = useField<number>(serviceIdField);
  const [moduleField] = useField<number>(moduleFieldId);
  const [fieldLabel, , helpersLabel] = useField(fieldName);
  const [fieldIdValue] = useField(fieldIdName);

  const [trainingGroupData, setTrainingGroupData] = useState<SelectOptionType[]>([]);

  // загрузка справочиков
  useEffect(() => {
    const fetchTrainGroup = async (serviceId: number) => {
      const res = await serviceTemplateApi.getTrainingGroupsServiceClass({
        serviceId,
        programmModuleId: moduleField?.value,
      });

      setTrainingGroupData(res);

      const findCurrentTrainingGroup = res.find((item) => item.value === fieldIdValue.value) as TrainingGroupItemType &
        SelectOptionType;

      if (findCurrentTrainingGroup) {
        const { requestStartDate, requestEndDate, educationDateStart, educationDateEnd, groupVolume, volume } =
          findCurrentTrainingGroup;

        setCurrentTrainingGroup?.({
          requestStartDate,
          requestEndDate,
          educationDateStart,
          educationDateEnd,
          volume,
          groupVolume,
        });
      }
    };

    if (
      serviceField.value &&
      editMode &&
      (educationType !== EducationTypeEnum.ProfessionalEducation || moduleField?.value)
    ) {
      fetchTrainGroup(serviceField.value);
    } else {
      setTrainingGroupData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceField.value, editMode, moduleField?.value]);

  const handleSelect = (selected: any) => {
    setCurrentTrainingGroup?.({
      requestStartDate: selected?.requestStartDate,
      requestEndDate: selected?.requestEndDate,
      educationDateStart: selected?.educationDateStart,
      educationDateEnd: selected?.educationDateEnd,
      volume: selected?.volume,
      groupVolume: selected?.groupVolume,
    });
    helpersLabel.setValue(selected ? selected.label : fieldLabel);
  };

  return (
    <Field
      label={label}
      editMode={editMode}
      required={required}
      value={fieldLabel.value}
      defaultValue={defaultValue}
    >
      <FormikSelect
        name={fieldIdName}
        size="small"
        withSearch
        options={trainingGroupData}
        selectedValue={handleSelect}
        placeholder="Выберите..."
        disabled={disabled}
      />
    </Field>
  );
};

export default TrainingGroupField;

import React from 'react';
import { Push } from '@mosru/esz_uikit';
import { LmIcon } from '@mes-ui/lemma';
import { formatDate } from '../../../../../lib/utils/date';
import { RequestData } from '../../../../../types/requests';
import { checkIfData } from '../../../../../lib/utils';

type Props = {
  requestData?: RequestData;
};

const Person = ({ requestData }: Props) => {
  const getPersonData = () => {
    if (requestData) {
      const { child } = requestData;

      return {
        snils: child.snils,
        dateOfBirth: formatDate(child.dateOfBirth),
        documentTypeName: child.documentTypeName,
        name: `${checkIfData(child.lastName)} ${checkIfData(child.firstName)} ${checkIfData(child.middleName)}`,
        gender: child.sexName.slice(0, 3).toLowerCase(),
        info: `${child.series || '—'} ${child.number || '—'} от ${formatDate(child.dateOfIssue) || '—'}`,
      };
    }
  };

  const person = getPersonData();

  return (
    <div className="requests-check-details__item">
      <div className="requests-check-details__inner requests-check-details__user">
        <div className="requests-check-details__grid">
          <div>
            <div className="icon-group">
              <div className="icon-group__icon">
                <LmIcon
                  icon="filled-account-person"
                  size={20}
                  color="var(--LM-neutrals-day-600)"
                />
              </div>
              <div className="icon-group__text font-weight-bold">{person?.name}</div>
            </div>
            <Push size={4} />
            <div className="color-gray-dark flex">
              <Push
                size={28}
                orientation="horizontal"
              />
              {person?.dateOfBirth}
              <Push
                size={10}
                orientation="horizontal"
              />
              {person?.gender}
            </div>
          </div>
          <div>
            <Push size={2} />
            {requestData?.child.documentTypeName}
            <Push size={4} />
            <div className="color-gray-dark">{person?.info}</div>
          </div>
          <div>
            <Push size={2} />
            СНИЛС
            <Push size={4} />
            <div className="color-gray-dark">{person?.snils ?? '—'}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Person;
